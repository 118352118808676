import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Push Press 3×12`}</p>
    <p>{`DB Bent Side Laterals 3×12`}</p>
    <p>{`then, 3 Rounds for time of:`}</p>
    <p>{`20-DB Push Jerks (40/25’s)`}</p>
    <p>{`50ft Backward Sled Drag (135/90`}{`#`}{`)`}</p>
    <p>{`20-Burpees`}</p>
    <p>{`50ft Forward Sled Drag (135/90`}{`#`}{`)`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`We will be closed this Sunday for Easter.  A workout will be posted
for deload week on Sunday that you can do at home.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      